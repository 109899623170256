import { javascript } from '@api/all'
import { clone, removeUndefined, uniqueArray } from '@avvoka/shared'
import { Config } from '@js-from-routes/client'
import { defineStore } from 'pinia'
import { computed, ref, type UnwrapRef, watch } from 'vue'

// Disable auto conversion from snake_case to camelCase
const noop = (val: unknown) => val
Config.deserializeData = noop
Config.serializeData = noop

export const ensureHydrated = <
  S extends Record<string, unknown> & { hydrated: boolean },
  P extends keyof S
>(
  store: S,
  property: P,
  ...fields: ReadonlyArray<keyof S[P]>
) => {
  if (!store.hydrated) throw new Error('The store is not hydrated')
  if (store[property] == null)
    throw new Error(`The ${String(property)} in the store is not initialized`)
  const missing = fields.filter((item) => store[property][item] === undefined)
  if (missing.length)
    throw new Error(`The fields ${missing.join(', ')} are not hydrated`)
}

/**
 * Enum for the modes of a store.
 *
 * @enum {number}
 *
 * @property EditData - The store can be hydrated with new data. This means we are editing something that already exists.
 * @property NewData - The store cannot be hydrated with new data. This means we are creating something new (i.e. a new template).
 */
export enum StoreMode {
  EditData,
  NewData
}

/**
 * This function is used to manage the hydration process of a store.
 * It provides several utilities to handle the hydration status, loading state, error handling, and data management.
 *
 * @template HydratedData - A type that extends Backend.Models.Model. Represents the data that will be hydrated.
 *
 * @param route - The route that will be used to fetch the data for hydration. If null, no data will be fetched.
 *
 * @returns An object containing several utilities for managing the hydration process:
 * - hydrated: A ref indicating whether the store is hydrated.
 * - loading: A ref indicating whether the store is currently loading data.
 * - error: A ref containing any error that occurred during the hydration process.
 * - hydratedData: A ref containing the hydrated data.
 * - storeMode: A ref indicating the mode of the store (EditData or NewData).
 * - hydratedFields: A ref containing an array of the fields that have been hydrated.
 * - hydrateFn: A function that handles the hydration process.
 * - hydrate: A function that hydrates the store with data fetched from the provided route.
 * - hydratedComputed: A function that creates a computed property based on a field of the hydrated data.
 * - isFieldHydrated: A function that checks if a specific field is hydrated.
 */
export const useHydration = <const HydratedData extends Backend.Models.Model>(
  route: (typeof javascript)[keyof typeof javascript] | null
) => {
  const hydrated = ref(false)
  const loading = ref(false)
  const error = ref(null as unknown)
  const hydratedData = ref<HydratedData | null>(null)
  const storeMode = ref(StoreMode.EditData)
  const hydratedFields = ref<ReadonlyArray<keyof HydratedData>>([])

  const hydrateFn = async (fn: () => Promise<unknown>) => {
    loading.value = true
    try {
      await fn()
      hydrated.value = true
    } catch (err: unknown) {
      error.value = err
      console.error('Error while hydrating', err)
    } finally {
      loading.value = false
    }
  }

  const hydrate = async (
    parameters: Record<string, string | number>,
    fields: ReadonlyArray<keyof HydratedData>,
    force?: boolean
  ) => {
    if (storeMode.value == StoreMode.NewData) {
      console.warn(
        'hydrate() called on a store in NewData mode; Skipping hydration'
      )
      return
    }

    await hydrateFn(async () => {
      if (!force && hydratedData.value != null) {
        fields = fields.filter(
          (key) => !(key in (hydratedData.value as HydratedData))
        )
      }

      if (fields.length > 0 || force) {
        if (route) {
          const data = await route({
            ...parameters,
            fields
          })
          if (hydratedData.value == null) hydratedData.value = data
          else Object.assign(hydratedData.value, data)
          hydratedFields.value = uniqueArray([
            ...hydratedFields.value,
            ...fields
          ]) as typeof hydratedFields.value
        }
      }
    })
  }

  const hydratedComputed = <
    Key extends keyof HydratedData,
    Return = NonNullable<HydratedData[Key]>
  >(
    property: Key,
    dataMapper: (value: HydratedData[Key]) => Return = (value) =>
      value as Return
  ) => {
    return computed<Return>(() => {
      ensureHydrated(
        { hydrated: hydrated.value, hydratedData: hydratedData.value },
        'hydratedData',
        property as never
      )
      return dataMapper((hydratedData.value as HydratedData)[property])
    })
  }

  const isFieldHydrated = (field: keyof HydratedData) => {
    return hydratedFields.value.includes(
      field as (typeof hydratedFields.value)[number]
    )
  }
  return {
    hydrated,
    loading,
    hydrate,
    error,
    hydratedData,
    hydratedComputed,
    hydrateFn,
    storeMode,
    hydratedFields,
    isFieldHydrated
  }
}
/**
 * Type alias for the return type of the `defineStore` function.
 * This represents the data of an instantiated store.
 */
export type InstantiatedStoreData = ReturnType<ReturnType<typeof defineStore>>

/**
 * The `Hydration` type is an alias for the return type of the `useHydration` function.
 * It represents the object returned by `useHydration`, which includes several utilities for managing the hydration process of a store.
 * These utilities include:
 * - `hydrated`: A ref indicating whether the store is hydrated.
 * - `loading`: A ref indicating whether the store is currently loading data.
 * - `error`: A ref containing any error that occurred during the hydration process.
 * - `hydratedData`: A ref containing the hydrated data.
 * - `storeMode`: A ref indicating the mode of the store (EditData or NewData).
 * - `hydratedFields`: A ref containing an array of the fields that have been hydrated.
 * - `hydrateFn`: A function that handles the hydration process.
 * - `hydrate`: A function that hydrates the store with data fetched from the provided route.
 * - `hydratedComputed`: A function that creates a computed property based on a field of the hydrated data.
 * - `isFieldHydrated`: A function that checks if a specific field is hydrated.
 */
export type Hydration<HydratedData extends Backend.Models.Model> = ReturnType<
  typeof useHydration<HydratedData>
>

/**
 * Type alias for a store that has been hydrated.
 * This extends the `InstantiatedStoreData` type and adds properties for hydration status and hydrated fields.
 * It also includes a method to check if a specific field is hydrated.
 *
 * @property {boolean} hydrated - Indicates whether the store is hydrated.
 * @property {any} hydratedFields - Represents the fields that have been hydrated.
 * @property {(field: any) => boolean} isFieldHydrated - A function that checks if a specific field is hydrated.
 */
export type HydratedStore = InstantiatedStoreData &
  Omit<UnwrapRef<Hydration<Backend.Models.Model>>, 'hydratedFields'> & {
    hydratedFields: ReadonlyArray<string>
  }

/**
 * Type alias for inferring the type of hydrated fields from a `HydratedStore`.
 * If the store has a `hydratedFields` property, the type of that property is inferred.
 * Otherwise, the type is `never`.
 *
 * @template T - A type that extends `HydratedStore`.
 */
export type InferHydratedFields<T extends HydratedStore> = T extends {
  hydratedFields: infer Fields
}
  ? Fields extends ReadonlyArray<string>
    ? Fields[number]
    : never
  : never

/**
 * This function is used to watch when specific properties of a store are hydrated.
 * It watches the 'hydrated' property of the store and when it becomes true, it starts watching the 'hydratedFields' property.
 * When all the specified properties are found in 'hydratedFields', it calls the provided callback function and stops watching.
 *
 * @template T - A type that extends HydratedStore. Represents the store that will be watched.
 * @template Key - A type that is inferred from the 'hydratedFields' of the store T. Represents the properties that will be watched.
 *
 * @param store - The store that will be watched.
 * @param properties - An array of properties that will be watched in the store.
 * @param callback - A function that will be called when all the specified properties are hydrated.
 */
export const onStorePropertiesHydrated = <
  const T extends HydratedStore,
  const Key extends InferHydratedFields<T>
>(
  store: T,
  properties: Key[],
  callback: VoidFunction
) => {
  const stopStore = watch(
    () => store.hydrated,
    (hydrated, oldHydrated) => {
      if (hydrated) {
        // When the store is 'immediately' hydrated, the 'oldHydrated' value is undefined and 'stopStore' is not initialized yet.
        // Note that writing "setTimeout(stopStore)" would still throw an error because
        // the variable is not initialized yet.
        if (oldHydrated === undefined) setTimeout(() => stopStore())
        else stopStore()

        const stopHydratedFields = watch(
          store.hydratedFields,
          (fields, oldFields) => {
            const missing = properties.filter(
              (property) => !store.isFieldHydrated(property)
            )
            if (!missing.length) {
              callback()
              // When the hydratedFields are 'immediately' hydrated, the 'oldFields' value is undefined and 'stopHydratedFields' is not initialized yet.
              // Note that writing "setTimeout(stopHydratedFields)" would still throw an error because
              // the variable is not initialized yet.
              if (oldFields === undefined)
                setTimeout(() => stopHydratedFields())
              else stopHydratedFields()
            }
          },
          { immediate: true }
        )
      }
    },
    { immediate: true }
  )
}

export type DocxSettings =
  CamelCasedProps<Backend.Models.TemplateVersion.DocxSettings>

export const useDocxSettings = <
  T extends
    | Backend.Models.TemplateVersion
    | Backend.Models.Document
    | Backend.Models.CustomClauseVariant
>(
  hydration: Hydration<T>
) => {
  // Used to replace docx_settings when importing
  const setDocxSettings = (
    settings: Backend.Models.TemplateVersion.DocxSettings
  ) => {
    if (hydration.hydratedData) {
      hydration.hydratedData.value!.docx_settings = settings
    }
  }

  // Returns camel-cased docx settings
  const docxSettings = hydration.hydratedComputed(
    'docx_settings',
    (settings) => {
      return removeUndefined({
        formats: settings.formats,
        docxNamesByOrigin: settings.docx_names_by_origin,
        stylesRelations: settings.stylesRelations,
        inactiveFormats: settings.inactiveFormats,
        metadata: settings.metadata,
        version: settings.version,
        dataDocxRef: settings['data-docx-ref']
      } satisfies DocxSettings)
    }
  )

  // Returns cleaned up docx settings for saving on backend
  const docxSettingsForBackend = hydration.hydratedComputed(
    'docx_settings',
    (rawSettings) => {
      const settingKeysToRemove = [
        'inactiveFormats',
        'stylesRelations'
      ] as const
      const formatKeysToRemove = ['active', 'checked', 'key'] as const

      const settings = clone(rawSettings)
      for (const key of settingKeysToRemove) {
        delete settings[key]
      }

      for (const format of Object.values(settings.formats)) {
        for (const key of formatKeysToRemove) {
          //@ts-expect-error TS7053
          delete format[key]
        }
      }

      return settings
    }
  )

  // Returns default style
  const defaultStyle = computed<
    Backend.Models.TemplateVersion.Style & { key: string }
  >(() => {
    const entries = Object.entries<Backend.Models.TemplateVersion.Style>(
      docxSettings.value?.formats ?? {}
    )
    const result =
      entries.find(([, value]) => value['default'] === true) ??
      entries.find(([key]) => key === 'Normal')

    return {
      key: result?.[0] ?? '',
      ...(result?.[1] ?? {})
    } as const
  })

  const setDefaultStyle = (key: string) => {
    delete hydration.hydratedData.value!.docx_settings.formats[
      defaultStyle.value.key
    ].default
    hydration.hydratedData.value!.docx_settings.formats[key].default = true
  }

  watch(
    hydration.hydratedData,
    (data) => {
      const docxSettings = data?.docx_settings
      if (docxSettings == null) return

      // Set default values
      if (docxSettings.formats == null) docxSettings.formats = {}
      if (docxSettings.inactiveFormats == null)
        docxSettings.inactiveFormats = {}
      if (docxSettings.docx_names_by_origin == null)
        docxSettings.docx_names_by_origin = {}
      if (docxSettings.metadata == null) docxSettings.metadata = {}
      if (docxSettings.version == null) docxSettings.version = 1
    },
    { immediate: true }
  )

  return {
    docxSettings,
    docxSettingsForBackend,
    defaultStyle,
    setDocxSettings,
    setDefaultStyle,
    /** @deprecated use docxSettings */
    styles: docxSettings
  }
}

export const useDefaultDocxSettings =
  (): Backend.Models.TemplateVersion.DocxSettings => {
    return {
      docx_names_by_origin: {},
      stylesRelations: {},
      inactiveFormats: {},
      formats: {
        Normal: {
          definition: {
            fontSize: { size: '11' },
            font: { font: 'arial' }
          },
          name: 'Normal',
          default: true
        }
      },
      version: 1,
      metadata: {},
      'data-docx-ref': ''
    }
  }
