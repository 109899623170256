// JsFromRoutes CacheKey d1b7fc043bc27f4e4d8775e263674058
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  finish: definePathHelper('post', '/documents/finish'),
  entries: definePathHelper('get', '/documents/:id/entries'),
  dqSubmitModal: definePathHelper('post', '/documents/:id/dq_submit_modal'),
  importsXlsx: definePathHelper('post', '/documents/imports/xlsx')
}
